import { useRef, useState, useEffect, useLayoutEffect } from 'react';
import styles from './Carousel.module.scss';
import classNames from 'classnames';
import { debounce, isClientSide } from 'utility/functions';
import { Icon, IconsEnum } from 'components/Icons';
import { AnchorLink } from 'components/anchorLink';

const isSSR = isClientSide() ? false : true;
const myEffect = isSSR ? useEffect : useLayoutEffect;

type CarouselProps = {
  title: string;
  children: Array<JSX.Element>;
  classNameWrapper?: string;
  hasNoLeftPadding?: boolean;
  hasNoTopPadding?: boolean;
  hasNoBottomPadding?: boolean;
  hasNoPaddingRight?: boolean;
  classNameTitle?: string;
  subtitle?: string;
  cardContainerClassName?: string;
  classNameSubtitle?: string;
  url?: string;
  iconId?: IconsEnum;
  classNameIcon?: string;
};

export const Carousel = ({
  title,
  children,
  classNameTitle,
  subtitle,
  classNameSubtitle,
  url,
  cardContainerClassName,
  iconId,
  classNameIcon,
}: CarouselProps) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isAllChildVisible, setIsAllChildVisible] = useState<boolean>(true);

  const scroll = (direction: 'next' | 'prev') => {
    const container = scrollContainerRef.current;

    if (container) {
      let newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

      if (newIndex < 0) newIndex = 0;
      if (newIndex >= container.children.length) newIndex = container.children.length - 1;
      const targetElement = container.children[newIndex];
      if (targetElement instanceof HTMLElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
        const paddingLeft = parseFloat(getComputedStyle(container).paddingLeft);
        const targetOffset =
          targetElement.getBoundingClientRect().left - container.getBoundingClientRect().left - paddingLeft;

        container.scrollBy({
          left: targetOffset,
          behavior: 'smooth',
        });
        setCurrentIndex(newIndex);
      }
    }
  };

  myEffect(() => {
    if (isSSR) return;
    if (scrollContainerRef?.current) {
      const container = scrollContainerRef.current;

      const { scrollLeft, scrollWidth, clientWidth } = container;
      setIsAtStart(scrollLeft === 0);
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);

      const children = Array.from(container.children);
      const containerRect = container.getBoundingClientRect();
      const isAllVisbile = children.every((child: HTMLElement) => {
        const childRect = child.getBoundingClientRect();
        return childRect.left >= containerRect.left && childRect.right <= containerRect.right;
      });
      setIsAllChildVisible(isAllVisbile);
    }
  }, [scrollContainerRef?.current?.children, children, isAtStart, isAtEnd]);

  const handleScroll = debounce(() => {
    const container = scrollContainerRef.current;

    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      setIsAtStart(scrollLeft === 0);
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);

      let index = Array.from(container.children).findIndex((child: HTMLElement) => {
        return child.offsetLeft >= scrollLeft;
      });

      if (index > -1) {
        setCurrentIndex(index);
      }
    }
  }, 99);

  return (
    <>
      <div className={styles.titleContainer}>
        <div>
          {url ? (
            <h2 className={classNames(styles.title, classNameTitle!)}>
              <AnchorLink href={`/${url}`} className={styles.link}>
                {title}
                <Icon
                  iconId={iconId ? iconId : IconsEnum.ARROW}
                  className={classNameIcon ? classNameIcon : styles.titleIcon}
                  color="var(--color-primary)"
                />
              </AnchorLink>
            </h2>
          ) : (
            <h2 className={classNames(styles.title, classNameTitle)}>{title}</h2>
          )}

          {subtitle && <p className={classNames(styles.subtitle, classNameSubtitle)}>{subtitle}</p>}
        </div>
        {!isAllChildVisible && (
          <div className={styles.buttonsContainer}>
            <button className={styles.buttonPrev} onClick={() => scroll('prev')} disabled={isAtStart}></button>
            <button className={styles.buttonNext} onClick={() => scroll('next')} disabled={isAtEnd}></button>
          </div>
        )}
      </div>
      <div
        className={classNames(styles.cardContainer, cardContainerClassName)}
        ref={scrollContainerRef}
        onScroll={handleScroll}
      >
        {children}
      </div>
    </>
  );
};
