import { getDateAndHoursValue, getLiveMinutes, isLiveEvent } from 'features/sport';

import styles from './TimingLivePreMatch.module.scss';
import { useAvvenimento } from 'features/sport/hooks';
import { useMemo } from 'react';

export type TimingLivePreMatchProps = {
  avvenimentoKey?: string;
};

export const TimingLivePreMatch = ({ avvenimentoKey }: TimingLivePreMatchProps) => {
  const maybeAvvenimento = useAvvenimento(avvenimentoKey);

  const { timing, isLive } = useMemo(() => {
    const isLive = isLiveEvent(maybeAvvenimento);

    const [t0, t1] = getDateAndHoursValue(maybeAvvenimento?.dataOra);
    const prematchTime = `${t0} ${t1}`;

    const liveTime = isLive ? getLiveMinutes(maybeAvvenimento?.live?.matchTime) : undefined;

    return {
      isLive,
      timing: liveTime ?? prematchTime,
    };
  }, [maybeAvvenimento]);

  return (
    <div className={styles.dateAndTimeWrapper}>
      <div className={styles.wrapperLabel}>
        {isLive && <strong className={styles.liveLabel}>LIVE</strong>}
        {timing && (
          <span
            className={`${isLive ? styles.labelLive : styles.label}`}
            suppressHydrationWarning
            dangerouslySetInnerHTML={{ __html: timing }}
          />
        )}
      </div>
    </div>
  );
};
