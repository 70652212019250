import { useCartToggleHandler, useIsInCart } from 'features/carrello/hooks';
import { useEsito, useInfoEsito } from 'features/sport/hooks';

import { memo, useMemo } from 'react';
import styles from './ScommesseEsitoTipoTop.module.scss';
import { useQuotaVariation } from 'hooks';
import { useRouter } from 'next/router';
import { useTranslation } from 'hooks/useLingUI';
import classNames from 'classnames';

export type ScommesseEsitoTipoTopProps = {
  keyEsito?: string;
  scommessaKey: string;
  infoAggiuntivaKey: string;
  noAction?: boolean;
  classNameButton?: string;
  classNameContainer?: string;
  gtagAction?: () => void;
};

export const ScommesseEsitoTipoTop = memo(function ScommesseEsitoTipoTop({
  keyEsito,
  noAction,
  classNameButton,
  classNameContainer,
  gtagAction,
}: ScommesseEsitoTipoTopProps) {
  const { t } = useTranslation();

  const isActiveInCart = useIsInCart(keyEsito);
  const maybeEsito = useEsito(keyEsito);
  const onCartToggleHandler = useCartToggleHandler(keyEsito);

  const { quota, isActive: isEsitoActive, infoEsitoKey } = maybeEsito ?? {};

  const infoEsito = useInfoEsito(infoEsitoKey);
  // N.B. descrizione should come from infoEsito only, but temporarly we allow esito source for backward compatibility
  const { descrizione, descrizioneTrKey } = { ...infoEsito, ...maybeEsito };

  const pathname = useRouter().pathname;
  const [isArrowVisible, isIncreasing, isDecreasing] = useQuotaVariation(maybeEsito);

  const quotaValue = useMemo(
    () => (isEsitoActive && quota !== 0 ? (quota! < 10 ? quota!.toFixed(2) : quota) : '-'),
    [isEsitoActive, quota]
  );

  if (!maybeEsito) {
    return null;
  }

  return (
    <div className={`${styles.container} ${classNameContainer ? classNameContainer : ''}`}>
      <span className={styles.label}>{t(descrizioneTrKey, descrizione)}</span>
      <button
        disabled={!isEsitoActive || noAction || !pathname.includes('/sport')}
        className={`${styles.buttonQuota} ${classNameButton ? classNameButton : ''} ${
          isActiveInCart ? styles.active : ''
        } ${noAction ? styles.noAction : ''}`}
        onClick={() => {
          gtagAction && gtagAction();
          onCartToggleHandler();
        }}
      >
        <span
          className={classNames(styles.quotaWrapper, {
            [styles.changed]: isArrowVisible,
            [styles.increasing]: isIncreasing,
            [styles.decreasing]: isDecreasing,
          })}
        >
          {quotaValue}
        </span>
      </button>
    </div>
  );
});
