import { useInfoAggiuntivaIndex, useInfoTipo } from 'features/sport/hooks';

import { ScommesseEsitoTipoTop } from 'components/scommesseEsitoTipoTop';
import { gtag } from 'utility/functions';
import styles from './ScommesseInEvidenceCardFooter.module.scss';
import { useTranslation } from 'hooks/useLingUI';

export type ScommesseInEvidenceCardFooterProps = {
  scommessaKey: string;
  tipoScommessaId: string;
  infoAggiuntivaKey: string;
};

export const ScommesseInEvidenceCardFooter = ({
  scommessaKey,
  tipoScommessaId,
  infoAggiuntivaKey,
}: ScommesseInEvidenceCardFooterProps) => {
  const { t } = useTranslation();

  const infoTipo = useInfoTipo(tipoScommessaId);

  const infoAggIndex = useInfoAggiuntivaIndex(infoAggiuntivaKey);
  const { esitoKeyList } = infoAggIndex ?? {};

  const gtagAction = () => gtag({ section: 'Sport', detail: 'Eventi in evidenza', event: 'Click quota' }, true);

  return (
    <div className={styles.container}>
      <span className={styles.scommessaDescription}>{t(infoTipo?.descrizioneTrKey, infoTipo?.descrizione)}</span>
      <div className={styles.quoteContainer}>
        {esitoKeyList?.map((esitoKey) => (
          <ScommesseEsitoTipoTop
            key={esitoKey}
            keyEsito={esitoKey}
            scommessaKey={scommessaKey}
            classNameButton={styles.minWidth}
            infoAggiuntivaKey={infoAggiuntivaKey}
            gtagAction={gtagAction}
          />
        ))}
      </div>
    </div>
  );
};
