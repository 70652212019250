export { BannerLandscapeWrapper } from './BannerLandscapeWrapper';

import { BannerHeroSkeleton } from '../bannerHero';
import { BannerLandscapeProps } from './BannerLandscape';
import { useLazyLoad } from 'hooks';
import { useMemo } from 'react';
import useWindowSize from 'hooks/useWindowSize';

export const BannerLandscape = (props: BannerLandscapeProps) => {
  const { isMobile } = useWindowSize();

  const placeholder = <BannerHeroSkeleton />;
  const height = useMemo(() => (isMobile ? '22.4rem' : '30.4rem'), [isMobile]);

  return useLazyLoad({
    props,
    height,
    placeholder,
    doLoad: () => import('components/banner/bannerLandscape/BannerLandscape').then((mod) => mod.BannerLandscape),
  });
};
