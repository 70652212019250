import { CompetitorIcon } from 'components/scommesseTable/scommesseTableCompetitors';
import React from 'react';
import { ScommessaAvvenimentoLink } from 'components/scommessaAvvenimentoLink';
import { Score } from 'components/cards/availableStreamCard/components/availableStreamCardBody/AvailableStreamCardBody';
import { SportsCompetitor } from 'types/swagger';
import { breakpoint } from 'utility/constant';
import classnames from 'classnames';
import { isTruthy } from 'utility/functions';
import styles from './ScommesseInEvidenceCardBody.module.scss';
import { useTranslation } from 'hooks/useLingUI';
import useWindowSize, { enComparer } from 'hooks/useWindowSize';

export type ScommesseInEvidenceCardBodyProps = {
  dataList: Array<SportsCompetitor | Score>;
  avvenimentoKey: string;
};

export const ScommesseInEvidenceCardBody = ({ dataList, avvenimentoKey }: ScommesseInEvidenceCardBodyProps) => {
  const { t } = useTranslation();
  const { doCheck } = useWindowSize();

  const [isLarge] = doCheck([{ comparer: enComparer.greater, size: breakpoint.md }]);

  const hasMultiCompetitors = (index: number, array: Array<SportsCompetitor | Score>): boolean => {
    return (array ?? []).length > 2;
  };

  return (
    <ScommessaAvvenimentoLink
      avvenimentoKey={avvenimentoKey}
      className={classnames(styles.bodyContainer, { [styles.bodyContainerCenter]: dataList.length === 1 })}
    >
      <React.Fragment>
        {dataList.map((item: SportsCompetitor | Score, index: number, self) => {
          if (index === 1) {
            let { score } = item as Score;
            const splittedScore = score?.split('-').filter((s) => s.length > 0);
            return (
              isTruthy(score?.length) && (
                <strong
                  key={score}
                  className={classnames(styles.score, { [styles.scoreCenter]: score?.length === 1 })}
                  suppressHydrationWarning
                >
                  {splittedScore?.length > 1 ? (
                    <React.Fragment>
                      <span suppressHydrationWarning>{splittedScore[0]}</span>
                      {isLarge && <span suppressHydrationWarning>{score[score.indexOf('-')]}</span>}
                      <span suppressHydrationWarning>{splittedScore[1]}</span>
                    </React.Fragment>
                  ) : (
                    isLarge && score
                  )}
                </strong>
              )
            );
          }
          let { iconSrc, descrizione, descrizioneTrKey } = item as SportsCompetitor;
          const txt = t(descrizioneTrKey, descrizione);
          return (
            <div
              key={`${descrizione}-${index}`}
              className={classnames(
                styles.wrapperCompetitor,
                hasMultiCompetitors(index, self) ? styles.multipleCompetitor : styles.singleCompetitor
              )}
            >
              {iconSrc && (
                <div className={styles.imageContainer} suppressHydrationWarning={true} title={txt}>
                  <CompetitorIcon iconSrc={iconSrc} className={styles.image} />
                </div>
              )}
              <span className={styles.label} suppressHydrationWarning={true}>
                {txt}
              </span>
            </div>
          );
        })}
      </React.Fragment>
    </ScommessaAvvenimentoLink>
  );
};
