import { memo, useMemo } from 'react';
import { useAvvenimento, useInfoAggiuntivaIndex } from 'features/sport/hooks';

import { KeyManagerSport } from 'features/sport';
import { ScommesseInEvidenceCardBody } from './components/scommesseInEvidenceCardBody';
import { ScommesseInEvidenceCardFooter } from './components/scommesseEvidenceCardFooter';
import { ScommesseInEvidenceCardHeader } from './components/scommesseInEvidenceCardHeader';
import { TimingLivePreMatch } from 'components/timingLivePreMatch';
import { getUrlManifestazioneByAvvenimento } from 'features/sport/utils/utils';
import { mergeCompetitorWithScore } from './utils/utils';
import styles from './ScommesseInEvidenzaCard.module.scss';
import { toIconsEnum } from 'components/Icons';
import { useTranslation } from 'hooks/useLingUI';

export type ScommesseInEvidenzaCardProps = {
  scommessaInEvidenzaKey: string;
};

export const ScommesseInEvidenzaCard = memo(function ScommesseInEvidenzaCard({
  scommessaInEvidenzaKey,
}: ScommesseInEvidenzaCardProps) {
  const { t } = useTranslation();
  const { tipoScommessaId, avvenimentoKey, scommessaKey } = useMemo(
    () => new KeyManagerSport(scommessaInEvidenzaKey),
    [scommessaInEvidenzaKey]
  );
  // const scommessa = useScommessa(key.scommessaKey);
  const avvenimento = useAvvenimento(avvenimentoKey);

  const infoAggIndex = useInfoAggiuntivaIndex(scommessaKey);

  const { nextInfoAgg } = infoAggIndex ?? {};
  const disciplina = `DISCIPLINA_${avvenimento?.idDisciplina}`;

  if (avvenimento) {
    return (
      <div className={styles.container}>
        <ScommesseInEvidenceCardHeader
          disciplinaIcon={toIconsEnum(disciplina)}
          urlManifestazione={getUrlManifestazioneByAvvenimento(avvenimento)}
          manifestazioneLabel={avvenimento?.slugManifestazione!}
          manifestazioneLabelTrKey={avvenimento?.descrizioneManifestazioneTrKey}
        >
          <TimingLivePreMatch avvenimentoKey={avvenimentoKey} />
        </ScommesseInEvidenceCardHeader>
        <ScommesseInEvidenceCardBody
          avvenimentoKey={avvenimentoKey}
          dataList={mergeCompetitorWithScore(
            [avvenimento?.firstCompetitor ?? {}, avvenimento?.secondCompetitor ?? {}],
            avvenimento?.live?.score
          )}
        />
        {nextInfoAgg?.map((infoAggiuntivaKey) => (
          <ScommesseInEvidenceCardFooter
            key={`${scommessaKey}-${infoAggiuntivaKey}`}
            scommessaKey={scommessaKey}
            tipoScommessaId={tipoScommessaId}
            infoAggiuntivaKey={infoAggiuntivaKey}
          />
        ))}
      </div>
    );
  }
  return null;
});
